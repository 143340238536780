.home {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.home>img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.home .content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
}

.home .content .slogan {
  padding: 1.333333rem 0 0 0.666667rem;
}

.home .content .slogan>p {
  font-size: 0.6rem;
  color: #fff;
  font-weight: bold;
  font-family: SourceHanSansCN;
  margin: 0;
  opacity: 0;
  line-height: 1.6;
}

.home .content .slogan .one {
  -webkit-animation: text 1s 0s ease;
  animation: text 1s 0s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .slogan .two {
  -webkit-animation: text 1s .5s ease;
  animation: text 1s .5s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .slogan .three {
  -webkit-animation: text 1s 1s ease;
  animation: text 1s 1s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .slogan .four {
  -webkit-animation: text 1s 1.5s ease;
  animation: text 1s 1.5s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .cz {
  padding: 0.666667rem 0 0 1.333333rem;
  position: relative;
}

.home .content .cz>div {
  position: absolute;
  top: 50%;
  left: 46%;
  z-index: 10;
  font-size: 1.333333rem;
  color: #fff;
  font-weight: bold;
  font-family: SourceHanSansCN;
  transition: all 1s;
  animation: dl 1s 1.5s linear infinite alternate;
  -webkit-animation: dl 1s 1.5s linear infinite alternate;
}

.home .content .cz>div>span {
  opacity: 0;
  text-shadow: 0 0 .15em #fff;
}

.home .content .cz>div .one {
  -webkit-animation: cz 1s 1.5s ease;
  animation: cz 1s 1.5s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .cz>div .two {
  -webkit-animation: cz 1.5s 2s ease;
  animation: cz 1.5s 2s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.home .content .cz>img {
  transition: all 1s;
  animation: cz 1s 1.5s linear infinite alternate;
  -webkit-animation: cz 1s 1.5s linear infinite alternate;
  display: inline-block;
  width: 6.04rem;
  height: 5.986667rem;
  transform: scale(1);
  opacity: 0;
}

.home .content .bottom_cz {
  position: absolute;
  bottom: 0;
}

.home .content .bottom_cz .cz_bg {
  width: 9.333333rem;
  transform: rotate(290deg);
  position: relative;
  bottom: -0.8rem;
  left: 1.466667rem;
  animation: cz-bg 1.5s linear infinite alternate;
  -webkit-animation: cz-bg 1.5s linear infinite alternate;
}

.home .content .bottom_cz .cz_one {
  width: 8rem;
  position: absolute;
  left: 4.4rem;
  bottom: -1.066667rem;
  z-index: 20;
  -webkit-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
}

@-webkit-keyframes cz {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes cz {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

@-webkit-keyframes dl {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

@keyframes dl {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

@-webkit-keyframes text {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes text {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cz-bg {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes cz-bg {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}